

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.pdfLetterheadPreview {
  width: 1024px;
  height: 100%;

  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;

  position: relative;
  font-family: 'Roboto' !important;
}



.pdfLetterhead {
    width: 1024px;
    height: 1340px;

    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;

    position: relative;
    display:none;
    top:-3000px;
}

#hiddenReports {
    position:absolute;
    top:1000px;
}

.pdfReport {
    width: 1024px;
    height: 100%;

    position: relative;
    visibility: visible !important;
    padding: 10px;
    z-index: 1000;
}


.pdfReport > table {
    font-size: 13px;
    border: 0;
}

.pdfReport > table > tr:first-child > th {
    background-color: lighten(rgb(164, 188, 194), 100%);
    border: 0;
    font-weight:bold;
    color:white;
    font-size: 20px;
}

.pdfReport > table td {
    border: 0;
}


.pdfReport > table tr:nth-child(odd) {
    background-color: rgb(164, 188, 194, 1);
}

.pdfReport > table th:first-child, td:first-child {
    padding-left: 30px;
    width:5%;
}

.pdfReport > table th:nth-child(2), td:nth-child(2) {
    width:35%;
}

.pdfReport > table th:last-child, td:last-child {
    text-align: right;
    padding-right: 30px;
    width:20%;
}


.pdfReport {
    font-family: 'Roboto' !important;
}

#hospitalLogo {
    width:960px;
    height:352px;
    z-index: 1000;
    position:absolute;
    top:2500px;
    display:none;
}

.pdfReport .checkmark {
    position: relative;
    border: 1px solid rgb(164, 188, 194);
    height: 20px;
    width: 20px;
    margin-top:1px;
  }