
  
  .raffle {
    display: flex;
    justify-content: center; /* Horizontally center the video */
    align-items: flex-start; /* Align the video to the top */
    height: 100vh; /* Set the container height to the full viewport height */
    background-color: black; /* Background color of the container */
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure it's above the video */
  }
  
  .overlay img {
    max-width: 100%;
    max-height: 100%;
  }
  
  
  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black; /* Set the background color to black */
    z-index: -1;
  }
  
  .name {
    z-index: 100000;
  }
  
  .much-larger-font {
    font-size: 8em; /* Adjust the size as needed */
  }
  