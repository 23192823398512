/* Test.css */

/* Apply Starbucks green background to the entire component */
.starbucks-test {
    background-color: #00704a;
    color: #ffffff; /* Set text color to white */
    text-align: center; /* Center-align text */
    padding: 20px;
  }
  
  /* Style the header */
  .starbucks-header {
    background-color: #ffffff; /* White background for header */
    padding: 20px;
  }
  
  .starbucks-header h1 {
    color: #00704a; /* Starbucks green text color */
  }
  
  /* Style the main content */
  .starbucks-main {
    margin: 20px;
  }
  
  .starbucks-main p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .starbucks-button {
    background-color: #00704a; /* Starbucks green background for the button */
    color: #ffffff; /* White text color for the button */
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .starbucks-button:hover {
    background-color: #005b3d; /* Darker green on hover */
  }
  
  /* Style the footer */
  .starbucks-footer {
    background-color: #ffffff; /* White background for footer */
    padding: 10px;
    font-size: 14px;
  }
  
  .starbucks-footer p {
    color: #00704a; /* Starbucks green text color */
  }
  

  /* LocationSearch.css */

/* Apply Starbucks-like styling */
.starbucks {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .search-container-large { /* Class for the larger box */
    background-color: #ffffff;
    border: 1px solid #d4d4d4;
    padding: 20px;
    border-radius: 10px; /* Adjusted border radius for a rounded appearance */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 600px; /* Adjusted maximum width */
    width: 100%;
    margin: 0 auto; /* Center-align the box horizontally */
  }
  
  .search-container-large h2 {
    color: #00704a;
    margin-bottom: 20px;
  }
  
  .search-container-large label {
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
    color: #00704a;
  }
  
  .search-container-large input[type="text"],
  .search-container-large select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    font-size: 16px;
    color: #00704a;
  }
  
  .search-container-large button {
    background-color: #00704a;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .search-container-large button:hover {
    background-color: #005b3d;
  }
  
  /* Additional styles for loading and results */
  .search-container-large p {
    font-weight: bold;
    color: #00704a;
    margin-top: 20px;
  }
  
  .search-container-large .results {
    margin-top: 20px;
  }
  
  .search-container-large .results h3 {
    color: #00704a;
  }
  
  .search-container-large .results ul {
    list-style: none;
    padding: 0;
  }
  
  .search-container-large .results li {
    margin-bottom: 10px;
  }
  