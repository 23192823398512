/* Home.css */
body {
  background-color: black; /* Set the background color to black */
}

.home {
  display: flex;
  justify-content: center; /* Horizontally center the video */
  align-items: flex-start; /* Align the video to the top */
  height: 100vh; /* Set the container height to the full viewport height */
  background-color: black; /* Background color of the container */
}

.video-background {
  max-width: 100%;
  max-height: 100vh; /* Set the max height to the viewport height */
  object-fit: cover; /* Adjust the video sizing as needed */
  z-index: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's above the video */
}

.overlay img {
  max-width: 100%;
  max-height: 100%;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s linear; /* Adjust the duration here (e.g., 1s for 1 second) */
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black; /* Set the background color to black */
  z-index: -1;
}

.name {
  z-index: 10000;
  text-align: center;
}

.larger-font {
  font-size: 6em; /* Adjust the size as needed */
}
