.inspiring-quote {
    font-size: 18px;
    font-style: italic;
    text-align: center;
    color: #54585a; /* Change the color to your preference */
    margin: 20px 0; /* Add margin for spacing if needed */
  }
  

  .congratulations-message {

    text-align: center;

  }