/* Home.css */
body {
    background-color: white; /* Set the background color to black */
  }
  
  .small-grey-text {
    font-size: 12px;
    color: #888; /* Light grey color */
  }
  
  .keywordSearch {
    max-width: 500px;
    width: 100%;
    display: inline-block;
  }

  .search-result {
    height: 140px; /* Set the height as needed */
    padding: 20px; /* Add padding inside the well */
    background-color: #ffffff; /* White background color */
    border: 1px solid #ddd; /* Light grey border */
    border-radius: 4px; /* Rounded corners */
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for a 3D effect */
    margin-bottom: 20px;
  }