.summary-text {

    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  table th, table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
  
  table th {
    background-color: #2dccd3;
    color: #fff;
  }
  
  table tbody tr:nth-child(even) {
    background-color: #f5f5f5;
  }
  
  table tbody tr:nth-child(odd) {
    background-color: #fff;
  }
  

  
  p {
    color: #55575a;
    font-size: 18px;
    margin: 0;
    margin-top: 10px;
  }
  